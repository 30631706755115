import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from './pages/home/Home';
import PlayerPage from './pages/player/Player';
import { PlayersRankingContext } from './providers/PlayersRankingContext';
import { loadSpreadsheet } from './helpers/spreadsheet';


const App: React.FC = () => {
  const [rankingState, setRankingState] = useState<any[]>([]);

  function populateObjWithCat(newOb: any, exOb: any, newKey: string, exKey: string, proc?: (a: any) => any) {
    if (exOb[exKey]) {

      if (typeof proc === "function") {
        newOb[newKey] = proc(exOb[exKey]);
      } else {
        newOb[newKey] = exOb[exKey];
      }
    }
  }

  function replacer(str: string) {
    return parseInt(str.replace("RON", "").replace(",", ""), 10);
  }

  useEffect(() => {
    loadSpreadsheet((data: any[]) => {
      const _data: Array<any> = [];

      data.forEach(x => {
        const n = {} as any;

        const min = Math.min.apply(Math, [
          replacer(x["1"]) as number,
          replacer(x["2"]) as number,
          replacer(x["3"]) as number,
          replacer(x["4"]) as number
        ]);

        populateObjWithCat(n, x, "county", "JUDET");
        n["norma"] = min;

        _data.push(n);
      });

      _data.sort((a, b) => {
        return a["norma"] - b["norma"];
      });

      setRankingState(_data);

      setTimeout(() => {
        console.log(rankingState);
      }, 1000);
    });
  }, []);

  return (
    <Router>
      <PlayersRankingContext.Provider value={rankingState}>
        <div className="ui container" style={{marginTop: "30px", marginBottom: "30px"}}>
          <Route path="/" exact component={HomePage} />
        </div>
      </PlayersRankingContext.Provider>
    </Router>
  );
}

export default App;
