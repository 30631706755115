import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { PlayersRankingContext } from '../../providers/PlayersRankingContext';
import Preloaded from '../../components/preloaded/Preloaded';
import numeral from 'numeral';

const HomePage: React.FC = () => {
  const normeContext = useContext<any[]>(PlayersRankingContext);
  const [norme, setNorme] = useState<any[]>([]);

  useEffect(() => {
    setNorme(normeContext);
  }, [normeContext]);

  return (
    <div className="App">
      <div></div>

      <Preloaded isLoading={!norme.length}>
        <table className="ui celled table">
          <thead>
            <tr>
              <th>Judet</th>
              <th>Norma</th>
              <th>Impozit profit</th>
              <th>Total Impozite</th>
            </tr>
          </thead>

          <tbody>
            {norme.map((elem, index) => {
              return (
                <tr key={index}>
                  <td>{elem.county}</td>
                  <td>{numeral(elem.norma).format("0,0.[00]") + " RON"}</td>
                  <td>{numeral(elem.norma * 0.1).format("0,0.[00]") + " RON"}</td>
                  <td>{numeral(6240 + 2496 + (elem.norma * 0.1)).format("0,0.[00]") + " RON"}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Preloaded>

    </div>
  );
}

export default HomePage;
